import React, { useState } from 'react';
import './DeviceListModal.css'; // Import the CSS for styling
import { useTranslation } from 'react-i18next'; // Add this

const DeviceListModal = () => {
  const { t, i18n } = useTranslation(); // Add translation hook to access the current language

  const [isOpen, setIsOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
          <button onClick={openModal} className="open-modal-btn">
      {t('open_device_list')} {/* Translate this button text */}
      </button>
 


      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div 
            className="modal-content" 
            onClick={(e) => e.stopPropagation()}
            style={{
              direction: i18n.language === 'he' ? 'rtl' : 'ltr', // Change text direction based on language
              textAlign: i18n.language === 'he' ? 'left' : 'left' // Change alignment based on language
            }}
          >
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>

            {/* Device List */}
            <div className="device-list">
              <br/>
              <h3>Apple</h3>
              <h4>Phones</h4>
              <ul>
                <li>iPhone XS</li>
                <li>iPhone XS Max</li>
                <li>iPhone XR</li>
                <li>iPhone 11</li>
                <li>iPhone 11 Pro</li>
                <li>iPhone 11 Pro Max</li>
                <li>iPhone SE (2020)</li>
                <li>iPhone 12</li>
                <li>iPhone 12 Mini</li>
                <li>iPhone 12 Pro</li>
                <li>iPhone 12 Pro Max</li>
                <li>iPhone 13</li>
                <li>iPhone 13 Mini</li>
                <li>iPhone 13 Pro</li>
                <li>iPhone 13 Pro Max</li>
                <li>iPhone SE (2022)</li>
                <li>iPhone 14</li>
                <li>iPhone 14 Plus</li>
                <li>iPhone 14 Pro</li>
                <li>iPhone 14 Pro Max</li>
                <li>iPhone 15</li>
                <li>iPhone 15 Plus</li>
                <li>iPhone 15 Pro</li>
                <li>iPhone 15 Pro Max</li>
                <li>iPhone 16</li>
              </ul>

              <h4>Tablets</h4>
              <ul>
                <li>iPad Pro (all models with cellular)</li>
                <li>iPad Air (3rd generation and later)</li>
                <li>iPad (8th generation and later)</li>
                <li>iPad mini (5th generation and later)</li>
              </ul>

              <h3>Samsung</h3>
              <h4>Phones</h4>
              <ul>
                <li>Samsung Galaxy S20</li>
                <li>Samsung Galaxy S20+</li>
                <li>Samsung Galaxy S20 Ultra</li>
                <li>Samsung Galaxy S20 Hybrid Dual SIM</li>
                <li>Samsung Galaxy S21</li>
                <li>Samsung Galaxy S21+</li>
                <li>Samsung Galaxy S21 Ultra</li>
                <li>Samsung Galaxy S22</li>
                <li>Samsung Galaxy S22+</li>
                <li>Samsung Galaxy S22 Ultra</li>
                <li>Samsung Galaxy S23</li>
                <li>Samsung Galaxy S23+</li>
                <li>Samsung Galaxy S23 Ultra</li>
                <li>Samsung Galaxy S24</li>
                <li>Samsung Galaxy S24+</li>
                <li>Samsung Galaxy S24 Ultra</li>
                <li>Samsung Galaxy Note 20</li>
                <li>Samsung Galaxy Note 20 Ultra</li>
                <li>Samsung Galaxy Fold</li>
                <li>Samsung Galaxy Fold 2</li>
                <li>Samsung Galaxy Fold 3</li>
                <li>Samsung Galaxy Fold 4</li>
                <li>Samsung Galaxy Fold 6</li>
                <li>Samsung Galaxy Z Flip</li>
                <li>Samsung Galaxy Z Flip3 5G</li>
                <li>Samsung Galaxy Z Flip4</li>
                <li>Samsung Galaxy Z Flip6</li>
                <li>Samsung Galaxy Z Fold2 5G</li>
                <li>Samsung Galaxy Z Fold3 5G</li>
                <li>Samsung Galaxy Z Fold4</li>
                <li>Galaxy A55 5G</li>
                <li>Samsung Flip 1</li>
                <li>Samsung Flip 3</li>
                <li>Samsung Flip 4</li>
                <li>OnePlus 11</li>
                <li>OnePlus 10 Pro</li>
                <li>OnePlus 9 Pro</li>
              </ul>

              <h4>Tablets</h4>
              <ul>
                <li>Samsung Galaxy Tab S8</li>
                <li>Samsung Galaxy Tab S8+</li>
                <li>Samsung Galaxy Tab S8 Ultra</li>
                <li>Samsung Galaxy Tab S7</li>
                <li>Samsung Galaxy Tab S7+</li>
                <li>Samsung Galaxy Tab S6</li>
                <li>Samsung Galaxy Tab S6 Lite 5G</li>
              </ul>

              <h3>Google Pixel</h3>
              <h4>Phones</h4>
              <ul>
                <li>Google Pixel 2</li>
                <li>Google Pixel 2 XL</li>
                <li>Google Pixel 3</li>
                <li>Google Pixel 3 XL</li>
                <li>Google Pixel 3a</li>
                <li>Google Pixel 3a XL</li>
                <li>Google Pixel 4</li>
                <li>Google Pixel 4 XL</li>
                <li>Google Pixel 4a 5G</li>
                <li>Google Pixel 5</li>
                <li>Google Pixel 5a</li>
                <li>Google Pixel 6</li>
                <li>Google Pixel 6 Pro</li>
                <li>Google Pixel 6a</li>
                <li>Google Pixel 7</li>
                <li>Google Pixel 7 Pro</li>
                <li>Google Pixel 8</li>
                <li>Google Pixel 8 Pro</li>
                <li>Google Pixel 9</li>
              </ul>

              <h4>Tablets</h4>
              <ul>
                <li>Google Pixel Slate (with LTE)</li>
              </ul>

              <h3>Huawei</h3>
              <h4>Phones</h4>
              <ul>
                <li>Huawei P40</li>
                <li>Huawei P40 Pro</li>
                <li>Huawei P40 Pro+</li>
                <li>Huawei P50 Pro</li>
                <li>Huawei Mate 40 Pro</li>
                <li>Huawei Mate Xs 2</li>
                <li>Huawei Mate X2</li>
                <li>Huawei P70</li>
                <li>Huawei P70 Pro</li>
                <li>Huawei P70 Art</li>
              </ul>

              <h3>Sony</h3>
              <h4>Phones</h4>
              <ul>
                <li>Sony Xperia 10 III Lite</li>
                <li>Sony Xperia 10 IV</li>
                <li>Sony Xperia 10 V</li>
                <li>Sony Xperia Ace III</li>
                <li>Sony Xperia 1 IV</li>
                <li>Sony Xperia 1 V</li>
                <li>Sony Xperia 1 VI</li>
                <li>Sony Xperia 5 IV</li>
              </ul>

              <h3>Xiaomi</h3>
              <h4>Phones</h4>
              <ul>
                <li>Xiaomi 12T Pro</li>
                <li>Xiaomi 13</li>
                <li>Xiaomi 13 Lite</li>
                <li>Xiaomi 13 Pro</li>
                <li>Xiaomi 13T Pro</li>
                <li>Xiaomi 14 Series</li>
                <li>Xiaomi Redmi Note 11 Pro 5G (Japan only)</li>
              </ul>

              <h3>Other Android Mobiles</h3>
              <h4>Phones</h4>
              <ul>
                <li>Motorola Razr (2019, 5G)</li>
                <li>Motorola Razr 5G</li>
                <li>Gemini PDA</li>
                <li>Rakuten Mini</li>
                <li>Rakuten Big-S</li>
                <li>Rakuten Big</li>
                <li>Rakuten Hand</li>
                <li>Rakuten Hand 5G</li>
                <li>Surface Pro X</li>
                <li>Honor Magic 4 Pro</li>
                <li>Fairphone 4</li>
                <li>Sharp Aquos Sense 6s</li>
                <li>Sharp Aquos Wish</li>
                <li>DOOGEE V30</li>
                <li>Nuu Mobile X5</li>
                <li>Oppo Find X3 Pro</li>
                <li>Oppo Find X5</li>
                <li>Oppo Find X5 Pro</li>
                <li>Oppo Find N2 Flip</li>
                <li>Oppo Reno 5A</li>
                <li>Oppo Reno 6 Pro 5G</li>
                <li>Oppo A55s 5G</li>
              </ul>

              <h3>Nokia</h3>
              <h4>Phones</h4>
              <ul>
                <li>Nokia XR20</li>
                <li>Nokia 8.3 5G</li>
              </ul>

              <h3>Lenovo</h3>
              <h4>Phones</h4>
              <ul>
                <li>Lenovo ThinkPhone</li>
              </ul>

              <h3>Asus</h3>
              <h4>Phones</h4>
              <ul>
                <li>Asus ROG Phone 5</li>
                <li>Asus Zenfone 8</li>
              </ul>

              <h3>Realme</h3>
              <h4>Phones</h4>
              <ul>
                <li>Realme GT Series (select models)</li>
              </ul>

              <h3>Vivo</h3>
              <h4>Phones</h4>
              <ul>
                <li>Vivo X60 Series (select models)</li>
              </ul>

              <h3>Microsoft Surface Devices</h3>
              <h4>Phones</h4>
              <ul>
                <li>Surface Duo 2</li>
              </ul>

              <h4>Tablets</h4>
              <ul>
                <li>Surface Pro X</li>
                <li>Surface Pro 8</li>
              </ul>

              <h3>Tablets</h3>
              <h4>Samsung Galaxy Tab</h4>
              <ul>
                <li>Samsung Galaxy Tab S8</li>
                <li>Samsung Galaxy Tab S8+</li>
                <li>Samsung Galaxy Tab S8 Ultra</li>
                <li>Samsung Galaxy Tab S7</li>
                <li>Samsung Galaxy Tab S7+</li>
                <li>Samsung Galaxy Tab S6</li>
                <li>Samsung Galaxy Tab S6 Lite 5G</li>
              </ul>

              <h4>Microsoft Surface</h4>
              <ul>
                <li>Surface Pro X</li>
                <li>Surface Pro 8 (with LTE Advanced)</li>
                <li>Surface Pro 7+ (with LTE)</li>
                <li>Surface Go 3 (with LTE)</li>
              </ul>

              <h4>Huawei MatePad</h4>
              <ul>
                <li>Huawei MatePad Pro 12.6 (with 5G)</li>
              </ul>

              <h4>Lenovo Tab</h4>
              <ul>
                <li>Lenovo Tab P12 Pro (with 5G)</li>
              </ul>

              <h4>Asus Tablet</h4>
              <ul>
                <li>Asus ROG Flow Z13 (with LTE)</li>
                <li>Asus ZenPad 3S 10 (with LTE)</li>
              </ul>

              <h4>Other Notable Tablets</h4>
              <ul>
                <li>Honor MagicPad (select models with cellular)</li>
                <li>Realme Pad (select models with LTE)</li>
                <li>Xiaomi Pad 5 Pro (with LTE)</li>
              </ul>

              <h4>Other Devices</h4>
              <ul>
                <li>Surface Duo 2</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceListModal;
