/* global gtag */
import React, { useState, useRef } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { sendInviteEmail } from './sendEmail'; // Adjust the path if needed
import './PriceDetails.css'; // Ensure the path is correct
import { useTranslation } from 'react-i18next'; // Add translation hook
import ThankYouModal from './ThankYouModal'; // Import the Thank You Modal component
import PriceDetails from './PriceDetails';

const Payment = ({ selectedBundle }) => {
  const { t, i18n } = useTranslation(); // Translation and language context

  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const emailRef = useRef(''); // Use ref instead of state for email
  const [emailError, setEmailError] = useState(''); // State to store email validation error

  const VAT_PERCENTAGE = 0.17; // Example VAT percentage, 17%
  const bundlePrice = selectedBundle ? Number(selectedBundle.price) || 0 : 0; // Ensure selectedBundle.price is a number
  const vatAmount = bundlePrice * VAT_PERCENTAGE;
  const priceWithoutVAT = bundlePrice - vatAmount;
  const gtag_report_conversion = (url) => {
    const callback = function () {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      send_to: 'AW-16683887331/9cRqCMn0vNoZEOPVv5M-',
      transaction_id: '', // Optional: Fill with real transaction ID if needed
      event_callback: callback
    });
    return false;
  };
 

  const handleApprove = async (selectedBundle, actions) => {
    try {
      gtag_report_conversion();
      const order = await actions.order.capture();
      processOrderData(order, selectedBundle, emailRef.current); // Use emailRef.current here
    } catch (error) {
      console.error('Payment approval failed:', error);
    }
  };

  const processOrderData = async (order, selectedBundle, currentEmail) => {
    try {
      console.log("Email before sending invite:", currentEmail); // Log the email to check if it's populated
      await sendInviteEmail(currentEmail, selectedBundle, order);
      setShowModal(true); // Show modal when payment is approved
      console.log('Payment and Firestore update successful:', order);
    } catch (error) {
      console.error('Processing order data failed:', error);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    emailRef.current = e.target.value; // Update the ref instead of state
    if (!validateEmail(e.target.value)) {
      setEmailError(t('invalid_email'));
    } else {
      setEmailError('');
    }
  };
 
  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div
      style={{
        textAlign: i18n.language === 'he' ? 'right' : 'left',
        direction: i18n.language === 'he' ? 'rtl' : 'ltr',
      }}
    >

      {selectedBundle ? (
        <div>
          <br />
          <br />
          <PriceDetails
            selectedBundle={selectedBundle}
            priceWithoutVAT={priceWithoutVAT}
            vatAmount={vatAmount}
            bundlePrice={bundlePrice}
          />
          {/* Email Input Field */}
          <br></br>
          <label htmlFor="email">{t('email_address')}:</label>
          <div className="email-input-container">
            <input
              type="email"
              id="email"
              defaultValue={emailRef.current} // Use defaultValue instead of value
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className="error-message">{emailError}</p>}
          </div>
          <label>*Your eSIM will be sent to this email address</label>
          <br></br>

          <br />
          <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
          <p style={{ marginBottom: '0px' }}>{t('paypal_instructions')}</p>
            <div id="paypal-button-container">
              <PayPalButtons
                style={{ shape: 'pill', color: 'blue', layout: 'vertical', label: 'pay' }}
                createOrder={(data, actions) =>
                  actions.order.create({
                    purchase_units: [
                      {
                        amount: { value: bundlePrice.toString() },
                        description: selectedBundle.data,
                      },
                    ],
                    application_context: { shipping_preference: 'NO_SHIPPING' },
                  })
                }
                onApprove={(data, actions) => {
                  handleApprove(selectedBundle, actions);
                }}
                onError={(err) => {
                  console.error('Error during the payment process:', err);
                  alert('An error occurred during the payment process.');
                }}
              />
            </div>
          </PayPalScriptProvider>
          <ThankYouModal show={showModal} handleClose={() => setShowModal(false)} />
        </div>
      ) : (
        <p>{t('no_bundle_selected')}</p>
      )}
    </div>
  );
};

export default Payment;
