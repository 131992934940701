import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import useCatalog from './catalog';
import './Step2.css'; // Import custom CSS for responsive styles

// Helper function to check if language is RTL
const isRTL = (language) => {
  return ['he', 'ar', 'fa', 'ur'].includes(language);
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const { i18n } = useTranslation();
  const rtl = isRTL(i18n.language);

  return (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        backgroundColor: '#006EFF',
        color: 'white',
        padding: '20px 50px',
        fontSize: '25px',
        borderRadius: '30px',
        textDecoration: 'none',
        display: 'inline-block',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      {rtl ? (
        <>
          &#x25BC; {children}
        </>
      ) : (
        <>
          {children} &#x25BC;
        </>
      )}
    </a>
  );
});

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const { i18n, t } = useTranslation();
    const rtl = isRTL(i18n.language);
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={{
          ...style,
          maxHeight: '250px',
          overflowY: 'auto',
          direction: rtl ? 'rtl' : 'ltr',
          textAlign: rtl ? 'right' : 'left',
        }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder={t('type_to_search')}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ textAlign: rtl ? 'right' : 'left' }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toLowerCase().includes(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

const Step2 = ({
  currentStep,
  selectedCountry,  // Make sure selectedCountry is destructured here
  setSelectedCountry,
  handleCountrySelect,
  selectedDays,  // Make sure selectedDays is destructured here
  handleDaysChange,
  totalPrice,  // Make sure totalPrice is destructured here
  countries,
  handleNext,
}) => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleRefresh = () => {
    window.location.reload(); // Refresh the page when the Back button is clicked
  };

  return (
    <>
      {currentStep === 2 && (
        <div className={`content-container ${isMobile ? 'mobile' : ''}`}>
          <div className="image-container">
            <img
              src={isMobile ? '/bikermobile.jpeg' : '/biker.jpeg'}
              alt="Your Image"
              className={isMobile ? 'top-image' : 'left-image-large rounded-image'}
            />
          </div>

          <div className="step-container">
            <h1>Where are you flying to?</h1>
            <br />

            <div className="dropdown-container">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {selectedCountry ? selectedCountry.name : t('select_country')}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  {countries
                    .sort((a, b) => a.name.localeCompare(b.name, i18n.language))
                    .map((country, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={country.name}
                        onClick={() => handleCountrySelect(country)}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <br />

            {selectedCountry && (
              <div className="days-selection">
                <h1>How Many Days?</h1>

                <select
                  value={selectedDays}
                  onChange={handleDaysChange}
                  className="day-dropdown centered-dropdown"
                >
                  {Array.from({ length: 30 }, (_, i) => i + 1).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <h4> <strong>UNLIMITED DATA PLAN </strong></h4>
                <div className="total-price">
                  <strong>{t('total_price')}: </strong>
                  <span>${totalPrice.toFixed(2)}</span>
                </div>
         
                <button className="buy-button" onClick={handleNext}>
                  {t('continue')}
                </button>
                <div>

                

                </div>
              </div>
              
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Step2;