import React from 'react';
import Terms from './terms';
import Terms_he from './terms_he';
import Privacy from './PrivacyPolicyModal';
import Privacy_he from './PrivacyPolicyModal_he';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer
      style={{
        backgroundColor: 'white', // Set the background to white
        color: 'black', // Changed text color to black for readability
        bottom: '0',
        left: '0',
        right: '0',
        width: '100%',
        textAlign: 'center',
        padding: '5px',
      }}
    >
      <div
        style={{
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
 
       
        {i18n.language === 'he' ? <Terms_he /> : <Terms />}
        {i18n.language === 'he' ? <Privacy_he /> : <Privacy />}
      </div>
    </footer>
  );
};

export default Footer;
