import React from 'react';

const Operators = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full height of the viewport
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Box shadow added to the container
        padding: '20px', // Optional: Adds some padding around the image
      }}
    >
      <img
        src="Operators.png" // Your image path
        alt="Operators"
        style={{
          width: '40%', // Default size for desktop
          height: 'auto', // Maintain aspect ratio
          maxWidth: '100%', // Ensure the image does not overflow on smaller screens
        }}
      />
      <style>
        {`
          @media (max-width: 768px) {
            img {
              width: 90%; // Adjust the width for mobile screens to fit better
            }
          }
        `}
      </style>
    </div>
  );
};

export default Operators;
