import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ThankYouModal.css';

const ThankYouModal = ({ show, handleClose }) => {
  const { t } = useTranslation();

  // Function to handle close and refresh the page
  const handleCloseAndRefresh = () => {
    handleClose(); // Close the modal
    window.location.reload(); // Refresh the page
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-wider" backdrop="static">
      <Modal.Header className="modal-header-centered">
        <Modal.Title className="modal-title-centered">{t('thank_you')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-centered">
        <p className="modal-text-centered">{t('payment_success_message')}</p>
      </Modal.Body>
      <Modal.Footer className="modal-footer-centered">
        <Button variant="primary" onClick={handleCloseAndRefresh} className="modal-button-centered">
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ThankYouModal;
