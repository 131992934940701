import React, { useState } from 'react';
import './PrivacyPolicyModal.css'; // Import customized CSS file

const PrivacyPolicyModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} className="open-modal-btn">
        Privacy Policy
      </button>
      {show && (
        <div className="modal-overlay">
<div className="modal-content english-modal" dir="ltr">
<span className="close-btn" onClick={handleClose}>
            </span>
            <h4>Privacy Policy</h4>
            <p><strong>Effective Date:</strong> September 28, 2024</p>

            <p>
              GorillaSprint LTD, operating the VIOFLY brand (www.VIOFLY.com) ("VIOFLY", "we", "us", or "our"), recognizes the importance of your privacy. This privacy policy describes how we collect, use, share, and protect your personal information.
            </p>

            <h5>Table of Contents:</h5>
            <ul>
              <li>1. Applicability of this Privacy Policy</li>
              <li>2. How we collect, disclose, and use personal information</li>
              <li>3. The information we collect</li>
              <li>4. Data retention</li>
              <li>5. How we use the information</li>
              <li>6. How we share personal information</li>
              <li>7. Your rights</li>
              <li>8. Data transfers and international transfers</li>
              <li>9. Security</li>
              <li>10. Changes to this policy</li>
              <li>11. Children</li>
              <li>12. Contact information</li>
              <li>13. Additional information for California residents</li>
            </ul>

            <h5>1. Applicability of this Privacy Policy</h5>
            <p>
              This privacy policy applies to all users of VIOFLY services worldwide, including users of the apps, website, and other features of the brand.
            </p>

            <h5>2. How we collect personal information</h5>
            <p>
              We collect your information in several ways, including directly, automatically, and from other sources. Data is collected when you create an account, make a purchase, or contact us through the website or app.
            </p>

            <h5>3. The information we collect</h5>
            <p>
              We collect information such as name, email address, payment details, and other information necessary to provide our services efficiently.
            </p>

            <h5>4. Data retention</h5>
            <p>
              We retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy.
            </p>

            <h5>5. How we use personal information</h5>
            <p>
              The use of your personal information includes processes such as completing transactions, improving services, and maintaining communication with our customers.
            </p>

            <h5>6. How we share personal information</h5>
            <p>
              We may share information with affiliates, business partners, and third-party service providers as needed.
            </p>

            <h5>7. Your rights</h5>
            <p>
              Depending on applicable laws, you may have rights such as access, correction, deletion, or objection to the processing of your personal information.
            </p>

            <h5>8. International data transfers</h5>
            <p>
              Your information may be processed in countries outside of your residence if required for the provision of services.
            </p>

            <h5>9. Security</h5>
            <p>
              We take security measures to protect your personal information, but we cannot guarantee complete security.
            </p>

            <h5>10. Changes to this Privacy Policy</h5>
            <p>
              We reserve the right to change this policy at any time. Significant changes will be posted on the website or communicated to our customers.
            </p>

            <h5>11. Children</h5>
            <p>
              Our services are not intended for children under the age of 13, and we do not knowingly collect personal information from children without parental consent.
            </p>

            <h5>12. Contact information</h5>
            <p>
              If you have any questions or comments regarding the privacy policy, please contact us at: contact@gorillasprint.com
            </p>

            <h5>13. Additional information for California residents</h5>
            <p>
              California law requires us to provide additional details about our privacy practices to California residents.
            </p>

            <div className="modal-footer">
              <button onClick={handleClose} className="close-modal-btn">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicyModal;
