import React, { useState } from 'react';
import './PrivacyPolicyModal_he.css'; // ייבוא קובץ ה-CSS המותאם

const PrivacyPolicyModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} className="open-modal-btn">
      מדיניות פרטיות
      </button>

      {show && (
        <div className="modal-overlay">
<div className="modal-content" dir="rtl"> {/* Hebrew modal direction */}
<span className="close-btn" onClick={handleClose}>
              &times;
            </span>
            <h4>מדיניות פרטיות</h4>
            <p><strong>תאריך כניסה לתוקף:</strong>  28 ספטמבר  2024</p>

            <p>
              חברת GorillaSprint LTD, המפעילה את המותג VIOFLY (www.VIOFLY.com) ("VIOFLY", "אנחנו", "אותנו" או "שלנו"), מכירה בחשיבות הפרטיות שלכם. מדיניות הפרטיות הזו מתארת את האופן שבו אנו אוספים, משתמשים, משתפים ומגנים על הפרטים האישיים שלכם.
            </p>

            <h5>תוכן עניינים:</h5>
            <ul>
              <li>1. הישימות של מדיניות הפרטיות הזו</li>
              <li>2. כיצד אנו אוספים פרטים אישיים, חושפים אותם ומשתמשים בהם</li>
              <li>3. הפרטים שאנו אוספים</li>
              <li>4. שמירת הנתונים</li>
              <li>5. כיצד אנו משתמשים בפרטים</li>
              <li>6. כיצד אנו משתפים פרטים אישיים</li>
              <li>7. הזכויות שלכם</li>
              <li>8. העברת נתונים והעברת נתונים בינלאומית</li>
              <li>9. אבטחה</li>
              <li>10. שינויים במדיניות הזו</li>
              <li>11. ילדים</li>
              <li>12. פרטים ליצירת קשר</li>
              <li>13. מידע נוסף עבור תושבי קליפורניה</li>
            </ul>

            <h5>1. הישימות של מדיניות הפרטיות הזו</h5>
            <p>
              מדיניות הפרטיות הזו חלה על כל המשתמשים בשירותים של VIOFLY ברחבי העולם, לרבות על משתמשי האפליקציות, אתר האינטרנט, ותכונות אחרות של המותג.
            </p>

            <h5>2. כיצד אנו אוספים פרטים אישיים</h5>
            <p>
              אנו אוספים את הפרטים שלכם במספר דרכים, לרבות באופן ישיר, אוטומטי, וממקורות אחרים. נתונים נאספים כאשר אתם יוצרים חשבון, מבצעים רכישה, או יוצרים קשר איתנו דרך האתר או האפליקציה.
            </p>

            <h5>3. הפרטים שאנו אוספים</h5>
            <p>
              אנו אוספים פרטים כמו שם, כתובת דוא"ל, פרטי תשלום, ומידע אחר שנחוץ כדי להעניק את השירותים שלנו בצורה תקינה.
            </p>

            <h5>4. שמירת הנתונים</h5>
            <p>
              אנו שומרים את הפרטים האישיים שלכם רק למשך הזמן הנדרש למילוי המטרות המפורטות במדיניות זו.
            </p>

            <h5>5. כיצד אנו משתמשים בפרטים האישיים</h5>
            <p>
              השימוש בפרטים האישיים שלכם כולל תהליכים כמו ביצוע עסקאות, שיפור השירותים, ושמירה על תקשורת עם לקוחותינו.
            </p>

            <h5>6. כיצד אנו משתפים פרטים אישיים</h5>
            <p>
              אנו עשויים לשתף את הפרטים עם חברות מסונפות, שותפים עסקיים וספקי שירותים חיצוניים בהתאם לצורך.
            </p>

            <h5>7. הזכויות שלכם</h5>
            <p>
              בהתאם לחוקים החלים, ייתכן ויש לכם זכויות כמו גישה, תיקון, מחיקה, או התנגדות לעיבוד הפרטים האישיים שלכם.
            </p>

            <h5>8. העברת נתונים בינלאומית</h5>
            <p>
              ייתכן שהפרטים שלכם יעובדו במדינות מחוץ למקום מגוריכם, במקרים שבהם העברת הנתונים נדרשת לצורך מתן השירותים.
            </p>

            <h5>9. אבטחה</h5>
            <p>
              אנו נוקטים אמצעי אבטחה כדי להגן על הפרטים האישיים שלכם, אך איננו יכולים להבטיח אבטחה מוחלטת.
            </p>

            <h5>10. שינויים במדיניות הפרטיות הזו</h5>
            <p>
              אנו שומרים לעצמנו את הזכות לשנות את המדיניות בכל עת. שינויים מהותיים יפורסמו באתר או יישלחו ללקוחותינו.
            </p>

            <h5>11. ילדים</h5>
            <p>
              השירותים שלנו אינם מיועדים לילדים מתחת לגיל 13 ואיננו אוספים במודע פרטים אישיים מילדים ללא הסכמת ההורים.
            </p>

            <h5>12. פרטים ליצירת קשר</h5>
            <p>
              אם יש לכם שאלות או הערות בנוגע למדיניות הפרטיות, אנא צרו קשר איתנו בכתובת: contact@gorillasprint.com
            </p>

            <h5>13. מידע נוסף עבור תושבי קליפורניה</h5>
            <p>
              החוק בקליפורניה מחייב אותנו לספק פרטים נוספים על נוהלי הפרטיות שלנו לתושבי קליפורניה.
            </p>

            <div className="modal-footer">
              <button onClick={handleClose} className="close-modal-btn">
                סגור
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicyModal;
