// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics'; // Import only getAnalytics

const firebaseConfig = {
  apiKey: "AIzaSyDtAa782oe2NSKPOi6509Xv7KqxyCOBSiY",
  authDomain: "esim-698ee.firebaseapp.com",
  projectId: "esim-698ee",
  storageBucket: "esim-698ee.appspot.com",
  messagingSenderId: "389310629779",
  appId: "1:389310629779:web:83809ec1fd14425ea54a66",
  measurementId: "G-DNNYS1CYHY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); // Initialize analytics correctly

// Firebase services
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const functions = getFunctions(app);

// Export services and analytics
export { auth, googleProvider, db, functions, analytics };
