import React from 'react';
import { FaDatabase, FaClock, FaGlobe, FaSignal } from 'react-icons/fa'; // Import icons
import { useTranslation } from 'react-i18next';
import './PriceDetails.css'; // Custom styles

const PriceDetails = ({ selectedBundle, priceWithoutVAT, vatAmount, bundlePrice }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="price-container"
      style={{
        backgroundImage: `url(/PriceDetails.webp)`, // Reference the image in the public folder
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px',
        borderRadius: '10px',
        textAlign: i18n.language === 'he' ? 'right' : 'left',
        direction: i18n.language === 'he' ? 'rtl' : 'ltr',
        color: 'white',
        height: '100%', // Ensure the full height is used
        display: 'flex',
        flexDirection: 'column', // Flexbox to arrange content vertically
        justifyContent: 'space-between', // Space out the content
      }}
    >
      {/* Price section (above the icons) */}
      <div className="price-section">
        <h2 className="price-title">eSIM, Unlimited, {selectedBundle.days} Days, {selectedBundle.country} </h2>

        {i18n.language === 'he' && (
          <>
            <div className="price-item">
              <span className="price-label">{t('price_without_vat')}:</span>
              <span className="price-value">${priceWithoutVAT.toFixed(2)}</span>
            </div>
            <div className="price-item">
              <span className="price-label">{t('vat')} (17%):</span>
              <span className="price-value">${vatAmount.toFixed(2)}</span>
            </div>
          </>
        )}

<div className="price-item total-price">
<span className="price-final">${parseFloat(bundlePrice).toFixed(2)}</span>
  </div>
      </div>
      <br></br>
      <br></br>
      {/* Icon list (at the bottom) */}
      <div className="icon-list">
        <div className="icon-item">
          <FaGlobe className="icon" />
          <span className="icon-text">{selectedBundle.country}</span>
        </div>

        <div className="icon-item">
          <FaDatabase className="icon" />
          <span className="icon-text">Unlimited</span>
        </div>

        <div className="icon-item">
          <FaClock className="icon" />
          <span className="icon-text">{selectedBundle.days} Days</span>
        </div>

        <div className="icon-item">
          <FaSignal className="icon" />
          <span className="icon-text">3G/4G/5G</span>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
