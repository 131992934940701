import React from 'react';
import './ESIMExplanation.css'; // CSS for styling the component

const ESIMExplanation = () => {
  return (
    <div className="esim-explanation">
      <h2>What is eSIM?</h2>
      <div className="esim-content">
        <div className="esim-text">
          <ul>
            <li>An eSIM is an electronic SIM card embedded into your device.</li>
            <li>No need for a physical SIM card to activate a mobile plan.</li>
            <li>It lets you switch carriers without swapping SIM cards.</li>
            <li>You can have multiple plans on the same device.</li>
            <li>No more hassle with inserting or removing SIM cards.</li>
            <li>Perfect for frequent travelers who change plans often.</li>
            <li>Activate data plans abroad without buying local SIMs.</li>
            <li>It saves time and effort, especially on international trips.</li>
          </ul>
        </div>
        <div className="esim-image">
          <img src="./explain.webp" alt="eSIM Illustration" />
        </div>
      </div>
    </div>
  );
};

export default ESIMExplanation;
