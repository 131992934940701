import React, { useState } from 'react';
import './terms_he.css'; // נוודא שיש קובץ CSS מותאם

const TermsModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} className="open-modal-btn">
      תנאים והתניות
      </button>

      {show && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close-btn" onClick={handleClose}>
              &times;
            </span>
            <h4>תנאים והתניות כלליים</h4>
            <p><strong>תאריך כניסה לתוקף:</strong> 28 ספטמבר  2024</p>

            <h5>1. תוקף התנאים וההתניות הכלליים</h5>
            <p>
              התנאים וההתניות הבאים חלים על כל השירותים הניתנים על ידי חברת GorillaSprint LTD (להלן "החברה"), המפעילה את המותג VIOFLY, בנוגע להפצת כרטיסי eSIM בתשלום מראש. התנאים וההתניות זמינים באתר <a href="https://www.VIOFLY.com">https://www.VIOFLY.com</a>. החברה רשאית לשנות את התנאים הללו מעת לעת בכפוף להסכמה מפורשת בכתב מצד המשתמשים.
            </p>

            <h5>2. תיאור השירותים</h5>
            <p><strong>2.1 הפצת כרטיסי eSIM:</strong> VIOFLY מספקת כרטיסי eSIM בתשלום מראש המאפשרים גישה לנתונים סלולריים במדינות שונות. הכרטיסים נרכשים דרך האתר או האפליקציה.</p>

            <p><strong>2.2 הרשמה לשימוש בשירותים:</strong> כל לקוח חייב להסכים לתנאים וההתניות הללו בעת ההרשמה לשירות.</p>

            <h5>3. תחילת החוזה וסיומו</h5>
            <p>
              החוזה ייכנס לתוקף בעת ביצוע ההזמנה באתר או באפליקציה של VIOFLY ויישאר בתוקף עד למחיקת ה-eSIM מהמכשיר או תום תוקף חבילת הנתונים.
            </p>

            <h5>4. תשלומים וחיובים</h5>
            <p><strong>4.1 תנאי תשלום:</strong> VIOFLY מציעה מגוון אפשרויות תשלום, כולל כרטיסי אשראי, PayPal ושירותי תשלום אחרים. המטבע העיקרי לתשלום הוא דולר אמריקאי ($), אך ניתן לבצע עסקאות במטבעות אחרים בהתאם לאזור.</p>

            <h5>5. משלוח והפעלה</h5>
            <p>כרטיסי eSIM יהיו זמינים להורדה דרך חשבון המשתמש באתר או באפליקציה של VIOFLY לאחר הרכישה.</p>

            <h5>6. מדיניות החזרות וביטולים</h5>
            <p>לקוחות רשאים לבקש החזר כספי במידה ואינם מצליחים להפעיל את כרטיס ה-eSIM לאחר תהליך פתרון בעיות.</p>

            <h5>7. חבות ואחריות</h5>
            <p>VIOFLY אינה אחראית לנזקים שנגרמים כתוצאה משירות שאינו זמין או אינו פועל כפי שצפוי.</p>

            <h5>8. תוקף והפעלה של כרטיסי eSIM</h5>
            <p>כרטיסי eSIM שנרכשו חייבים להיות מופעלים תוך פרק הזמן המצוין בתנאים.</p>

            <h5>9. תמחור ומבצעים</h5>
            <p>VIOFLY עשויה להציע מבצעים ומחירים מיוחדים לזמן מוגבל. מבצעים אלה כפופים לתנאים ספציפיים וייתכן שישתנו מעת לעת.</p>

            <h5>10. מעקב אחר מיקום</h5>
            <p>VIOFLY עשויה לאסוף ולעבד נתוני מיקום גיאוגרפי לצורך שיפור השירותים.</p>

            <div className="modal-footer">
              <button onClick={handleClose} className="close-modal-btn">
                סגור
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsModal;
