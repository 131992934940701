import React from 'react';
import './paysafely.css'; // Create a separate CSS file for styles

const PaySafely = () => {
  return (
    <div className="pay-safely-container">
      <div className="icon-container">
      <img src="/paysafely.webp" alt="MasterCard" className="payment-icon" />
      <img src="/mastercard-icon.svg" alt="MasterCard" className="payment-icon" />
      <img src="/paypal-icon.svg" alt="PayPal" className="payment-icon" />
        <img src="/visa_icon.svg" alt="Visa" className="payment-icon" />
        <img src="/GooglePay.svg" alt="Visa" className="payment-icon" />
      </div>
    </div>
  );
};

export default PaySafely;
