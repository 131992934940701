import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './reviews.css';

const Review = () => {
  const reviews = [
    {
      name: 'Emily.J',
      review: 'I had some issues setting it up, but their customer support was fantastic. Highly recommend!',
      date: 'September 10, 2024',
      image: '/profile4.webp', // Replace with actual image URL
    },
    {
      name: 'George.B',
      review: 'The eSIM service was incredibly fast and easy to set up. Perfect for my travels!',
      date: 'October 1, 2024',
      image: '/profile1.webp', // Replace with actual image URL
    },
    {
      name: 'Linda.S',
      review: 'VIOFLY made my trip to Europe so much easier. Amazing service and great data packages.',
      date: 'September 25, 2024',
      image: '/profile2.webp', // Replace with actual image URL
    },
    {
      name: 'Daniel.C',
      review: 'Best eSIM provider I’ve ever used. Affordable plans and super reliable connection!',
      date: 'September 18, 2024',
      image: '/profile3.webp', // Replace with actual image URL
    },
  
  
  ];

  return (
    <div className="review-container">
      <h2 className="review-title">Reviews</h2>
      <div className="review-grid">
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <div className="review-header">
              <img src={review.image} alt={`${review.name}'s profile`} className="profile-image" />
              <div>
                <h4 className="review-name">{review.name}</h4>
                <p className="review-date">{review.date}</p>
              </div>
            </div>
            <div className="review-stars">
              {[...Array(5)].map((_, i) => (
                <FontAwesomeIcon key={i} icon={faStar} className="star-icon" />
              ))}
            </div>
            <p className="review-text">{review.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
