import React, { useState } from 'react';
import './terms.css'; // Ensure the correct CSS file is linked

const TermsModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} className="open-modal-btn">
        Terms&Conditions
      </button>

      {show && (
        <div className="modal-overlay">
<div className="modal-content english-modal" dir="ltr">
<span className="close-btn" onClick={handleClose}>
              &times;
            </span>
            <h4>General Terms and Conditions</h4>
            <p><strong>Effective Date:</strong> September 28, 2024</p>

            <h5>1. Validity of the General Terms and Conditions</h5>
            <p>
              The following terms and conditions apply to all services provided by GorillaSprint LTD (hereinafter "the company"), operating the VIOFLY brand, regarding the distribution of prepaid eSIM cards. The terms and conditions are available on the website <a href="https://www.VIOFLY.com">https://www.VIOFLY.com</a>. The company may change these terms from time to time, subject to explicit written consent from users.
            </p>

            <h5>2. Description of Services</h5>
            <p><strong>2.1 Distribution of eSIM Cards:</strong> VIOFLY provides prepaid eSIM cards that offer access to mobile data in various countries. The cards are purchased through the website or app.</p>

            <p><strong>2.2 Registration for Service Use:</strong> Every customer must agree to these terms and conditions when registering for the service.</p>

            <h5>3. Start and Termination of the Contract</h5>
            <p>
              The contract will take effect upon placing an order on the VIOFLY website or app and will remain in effect until the eSIM is deleted from the device or the data package expires.
            </p>

            <h5>4. Payments and Charges</h5>
            <p><strong>4.1 Payment Terms:</strong> VIOFLY offers various payment options, including credit cards, PayPal, and other payment services. The primary currency for payment is the US Dollar ($), but transactions can be made in other currencies depending on the region.</p>

            <h5>5. Delivery and Activation</h5>
            <p>eSIM cards will be available for download via the user's account on the VIOFLY website or app after purchase.</p>

            <h5>6. Return and Cancellation Policy</h5>
            <p>Customers may request a refund if they are unable to activate the eSIM card after going through troubleshooting procedures.</p>

            <h5>7. Liability and Responsibility</h5>
            <p>VIOFLY is not responsible for damages caused by services that are unavailable or do not function as expected.</p>

            <h5>8. Validity and Activation of eSIM Cards</h5>
            <p>Purchased eSIM cards must be activated within the specified time period mentioned in the terms.</p>

            <h5>9. Pricing and Promotions</h5>
            <p>VIOFLY may offer limited-time promotions and special prices. These promotions are subject to specific terms and may change from time to time.</p>

            <h5>10. Location Tracking</h5>
            <p>VIOFLY may collect and process geographic location data to improve services.</p>

            <div className="modal-footer">
              <button onClick={handleClose} className="close-modal-btn">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsModal;
