// sendEmail.js
import React, { useState } from 'react';

export const sendInviteEmail = async (email,selectedBundle,order) => {
  const isLocalhost = window.location.hostname === 'localhost';

  //const functionUrl = isLocalhost
  //? 'http://localhost:5001/esim-698ee/us-central1/sendInviteEmail' // Emulator URL
  //: 'https://us-central1-esim-698ee.cloudfunctions.net/sendInviteEmail'; // Production URL

  //here i want to call the esimgo javascript and get it's respone

  const functionUrl = 'https://us-central1-esim-698ee.cloudfunctions.net/sendInviteEmail'; 

  

  try {
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, selectedBundle,order }),
    });

    const result = await response.json();

    if (response.ok && result.success) {
      console.log(`Email sent to ${email} with link: ${selectedBundle.bundle}`);
    } else {
      console.error(`Failed to send email: ${result.error}`);
    }
  } catch (error) {
    console.error('Error calling function:', error);
  }
};

const SendEmail = () => {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');

  return <div></div>;
};

export default SendEmail;
