import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faBarcode, faCog, faPlane, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from './ESIMInstructions.module.css';

const ESIMInstructions = () => {
  const [showInstructions, setShowInstructions] = useState(false);

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  return (
    <div className={styles.esimInstructions}>
      <button className={styles.toggleButton} onClick={toggleInstructions}>
        {showInstructions ? 'Hide Installation Instructions' : 'How to install?'}
      </button>

      {showInstructions && (
        <div className={styles.instructionsContent}>
          {/* Option for iPhone Users */}
          <div className={styles.optionContainer}>
            <h3><FontAwesomeIcon icon={faMobileAlt} /> Special Option for iPhone Users: Installation via Installation Link</h3>
            <p>
              iPhone users have a simpler option for installing the eSIM by clicking the installation link you will receive in the email.
            </p>
            <ul>
              <li>Open the email where you received the installation link for the eSIM.</li>
              <li>Click the link, and your iPhone will automatically open the eSIM installation settings.</li>
              <li>Confirm the installation and wait for the process to complete.</li>
            </ul>
            <p>
              After installation, make sure data roaming is enabled: Go to <strong>Settings</strong> > <strong>Connections</strong> > <strong>Mobile Networks</strong> > <strong>Data Roaming</strong> and ensure it is active.
            </p>
          </div>

          {/* Option 1 Container */}
          <div className={styles.optionContainer}>
            <h3><FontAwesomeIcon icon={faBarcode} /> Option 1: Scanning with the Device Camera</h3>
            <p><strong>Note:</strong> This option is suitable for those who can scan the barcode from a computer or another mobile device.</p>
            <ul>
              <li>Go to: <strong>Settings</strong> > <strong>Connections</strong> > <strong>SIM Manager</strong> > <strong>Add eSIM</strong> > <strong>Scan QR Code</strong>.</li>
              <li>When the device camera opens, scan the barcode sent to the email where you purchased the package. Then click "Add."</li>
            </ul>
            <p>Ensure that data roaming is enabled: Go to <strong>Settings</strong> > <strong>Connections</strong> > <strong>Mobile Networks</strong> > <strong>Data Roaming</strong> and ensure it is active.</p>
          </div>

          {/* Option 2 Container */}
          <div className={styles.optionContainer}>
            <h3><FontAwesomeIcon icon={faCog} /> Option 2: Manual Activation</h3>
            <p><strong>Note:</strong> This option is suitable for those who cannot scan the barcode with the camera.</p>
            <ul>
              <li>Go to: <strong>Settings</strong> > <strong>Connections</strong> > <strong>SIM Manager</strong> > <strong>Add eSIM</strong>.</li>
              <li>Instead of scanning a QR code, click "Enter Activation Code" and enter the code sent to the email where you purchased the package.</li>
            </ul>
            <p>Ensure that data roaming is enabled: Go to <strong>Settings</strong> > <strong>Connections</strong> > <strong>Mobile Networks</strong> > <strong>Data Roaming</strong> and ensure it is active.</p>
          </div>

          {/* Additional Information */}
          <div className={styles.additionalInfo}>
            <h3><FontAwesomeIcon icon={faPlane} /> Additional Information</h3>

            <h4>On the Day of Your Flight:</h4>
            <p>Turn off the local SIM: Go to <strong>Settings</strong> > <strong>Connections</strong> > <strong>SIM Manager</strong> > Turn off the Israeli SIM (usually <strong>sim1</strong> – you can identify the Israeli SIM by the number displayed).</p>

            <h4>Important Note:</h4>
            <p>If the package doesn’t work as expected at your destination, activate "Airplane Mode" on your device for 10 seconds, then turn it off. This will refresh the network settings.</p>

            <h4>On the Day of Your Return to Israel:</h4>
            <p>Turn on the local SIM: Go to <strong>Settings</strong> > <strong>Connections</strong> > <strong>SIM Manager</strong> > Turn on the Israeli SIM. Then turn off the eSIM.</p>

            <h4><FontAwesomeIcon icon={faClock} /> Operating Hours:</h4>
            <p>24/7 :)</p>
            <p>Having trouble with the installation? Have questions? Contact us via WhatsApp: <a href="Whatsapp:+97255-982-0737">Whatsapp:+97255-982-0737</a></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ESIMInstructions;