import React from 'react';

const ESIMInstructions = () => {
  return (
    <div >
      {/* Option for iPhone Users */}
      <div className="option-container">
        <h3>אופציה מיוחדת לבעלי אייפון: התקנה דרך לינק ההתקנה</h3>
        <p>
          למשתמשי אייפון, ישנה אפשרות פשוטה יותר להתקנת ה-eSIM באמצעות לחיצה על לינק ההתקנה שתקבלו באימייל.
        </p>
        <p>
          1. פתחו את האימייל שבו קיבלתם את לינק ההתקנה ל-eSIM.
        </p>
        <p>
          2. לחצו על הלינק והאייפון יפנה אוטומטית להגדרות ההתקנה של ה-eSIM.
        </p>
        <p>
          3. אשרו את ההתקנה והמתינו לסיום התהליך.
        </p>
        <p>
          לאחר ההתקנה, וודאו שהנתונים בנדידה פעילים: היכנסו ל<strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>רשתות תקשורת סלולרית</strong> > <strong>נדידת נתונים</strong> וודאו שהיא פעילה.
        </p>
      </div>

      {/* Option 1 Container */}
      <div className="option-container">
        <h3>אופציה 1: סריקה דרך מצלמת המכשיר</h3>
        <p>
          <strong>הערה:</strong> אופציה זו מתאימה למי שיכול לסרוק את הברקוד מהמחשב או ממכשיר סלולרי אחר.
        </p>
        <p>
          1. היכנסו ל: <strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>מנהל SIM</strong> > <strong>הוסף eSIM</strong> > <strong>סרוק קוד QR</strong>.
        </p>
        <p>
          2. כאשר תיפתח מצלמת המכשיר, סרקו את הברקוד שנשלח למייל בו רכשתם את החבילה. לאחר מכן, לחצו על "הוסף".
        </p>
        <p>
          וודאו שהנתונים בנדידה פעילים: היכנסו ל<strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>רשתות תקשורת סלולרית</strong> > <strong>נדידת נתונים</strong> וודאו שהיא פעילה.
        </p>
      </div>

      {/* Option 2 Container */}
      <div className="option-container">
        <h3>אופציה 2: הפעלה בהזנה ידנית</h3>
        <p>
          <strong>הערה:</strong> אופציה זו מתאימה למי שאין אפשרות לסרוק את הברקוד באמצעות המצלמה.
        </p>
        <p>
          1. היכנסו ל: <strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>מנהל SIM</strong> > <strong>הוסף eSIM</strong>.
        </p>
        <p>
          2. במקום סריקת קוד QR, לחצו על "הזן קוד הפעלה" והזינו את הקוד שנשלח למייל בו רכשתם את החבילה.
        </p>
        <p>
          וודאו שהנתונים בנדידה פעילים: היכנסו ל<strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>רשתות תקשורת סלולרית</strong> > <strong>נדידת נתונים</strong> וודאו שהיא פעילה.
        </p>
      </div>

      {/* Additional Information Container */}
      <div className="additional-info-container">
        <h3>מידע נוסף</h3>

        <h4>ביום הטיסה:</h4>
        <p>
          כיבוי הסים הישראלי: היכנסו ל<strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>מנהל SIM</strong> > כבו את הסים הישראלי (בדרך כלל <strong>sim1</strong> – תוכלו לזהות את הסים הישראלי לפי המספר שמופיע).
        </p>

        <h4>הערה חשובה:</h4>
        <p>
          אם החבילה אינה פועלת כמצופה ביעד, הפעילו את "מצב טיסה" במכשיר למשך 10 שניות ולאחר מכן כבו אותו. פעולה זו תרענן את הגדרות הרשת.
        </p>

        <h4>ביום החזרה לארץ:</h4>
        <p>
          הפעלת הסים הישראלי: היכנסו ל<strong>הגדרות</strong> > <strong>חיבורים</strong> > <strong>מנהל SIM</strong> > הדליקו את הסים הישראלי. לאחר מכן כבו את ה-eSIM.
        </p>

        <h4>שעות פעילות:</h4>
        <p>
          24/7 :)
        </p>
        <p>
          מסתבכים עם ההתקנה? יש שאלות? צרו קשר בוואטסאפ: <a href="Whatsapp:055-982-0737">Whatsapp:055-982-0737</a>
        </p>
      </div>
    </div>
  );
};

export default ESIMInstructions;
