import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next'; // Import for i18n

// Path to the CSV file
const csvFilePath = '/data/catalog.csv';

const useCatalog = () => {
  const { i18n } = useTranslation();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchPlan = async () => {
      const response = await fetch(csvFilePath);
      const csvText = await response.text();

      Papa.parse(csvText, {
        header: true,
        complete: (result) => {
          const data = result.data;
          const countriesMap = {};

          data.forEach((row) => {
            const {
              'countryHeb': countryHeb,
              'Country': country,
              'ISOCode': isoCode,
              'PricePerDay': pricePerDay // Assuming this is the column in the CSV
            } = row;

            if (!countriesMap[country]) {
              countriesMap[country] = {
                name: i18n.language === 'he' ? countryHeb : country,
                country: country,
                countryHeb: countryHeb,
                bundle: 'esim_UL_1D_' + isoCode +"_V2",
                pricePerDay: Number(pricePerDay) + 1, // Store price per day for calculation later
              };
            }
          });

          const countriesArray = Object.values(countriesMap);
          setCountries(countriesArray);
        }
      });
    };

    fetchPlan();
  }, [i18n.language]);

  return countries;
};

export default useCatalog;
